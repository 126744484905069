/* Card Styling */
.about-us-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin: 10px;
    flex: 1 1 calc(33.333% - 40px);
    max-width: calc(33.333% - 40px);
    box-sizing: border-box;
    opacity: 0;
    animation: fadeInCard 1s ease-in-out forwards;
    animation-delay: 1s;
    border-radius: 15px; /* Updated border-radius */
  }
  
  .about-us-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    border-radius: 15px; /* Maintain border-radius on hover */
  }
  
  /* Card Fade-In Animation */
  @keyframes fadeInCard {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Card Title Styling */
  .card-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #133F94; /* Blue */
    text-transform: capitalize;
  }
  
  /* Card Description Styling */
  .card-description {
    font-size: 1rem;
    color: #4F4F4F; /* Medium Gray */
    line-height: 1.8;
  }
  