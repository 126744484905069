/* Overall Header Styling */
.header {
  width: 100%;
  background-color: #f5f5f7; /* Light Gray */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.top-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  transition: padding 0.3s ease;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 90px; /* Prominent logo size */
  margin-right: 15px; /* Increased margin */
  border-radius: 9px;
  transition: height 0.3s ease, margin-right 0.3s ease;
}

.logo span {
  font-size: 32px; /* Large font size for the logo text */
  font-weight: bold;
  color: #133F94; /* Deep Blue */
}

nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin-left: 25px;
}

nav ul li button {
  color: #1d1d1d; /* Dark Gray */
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  position: relative; /* Required for the underline effect */
  transition: color 0.3s ease;
}

nav ul li button::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #133F94; /* Deep Blue */
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

nav ul li button:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.header-buttons {
  display: flex;
}

.header-buttons .button-outline {
  border: 2px solid #133F94; /* Deep Blue */
  color: #133F94; /* Deep Blue */
  background: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin-right: 10px;
  text-decoration: none;
  font-size: 18px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.header-buttons .button-outline:hover {
  background-color: #133F94; /* Deep Blue */
  color: #f5f5f7; /* Light Gray */
}

.header-buttons .button-filled {
  background-color: #133F94; /* Deep Blue */
  color: #f5f5f7; /* Light Gray */
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 18px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.header-buttons .button-filled:hover {
  background-color: #1d1d1d; /* Dark Gray */
  color: #f5f5f7; /* Light Gray */
}

.nav-toggle {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .top-nav {
    padding: 10px 20px;
  }

  .logo img {
    height: 70px;
    margin-right: 10px;
  }

  .logo span {
    font-size: 28px;
  }

  nav ul li {
    margin-left: 20px;
  }

  nav ul li button {
    font-size: 16px;
  }

  .header-buttons .button-outline,
  .header-buttons .button-filled {
    font-size: 16px;
    padding: 8px 16px;
  }
}

@media (max-width: 768px) {
  .top-nav {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 15px;
  }

  nav ul {
    flex-direction: column;
    display: none;
    width: 100%;
  }

  nav ul li {
    margin: 10px 0;
  }

  nav ul li button {
    padding: 10px 0;
    font-size: 18px;
  }

  .nav-toggle {
    display: block;
  }

  .nav-links.active {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 10px; /* Adjusted to ensure visibility */
    left: 10px; /* Adjusted to ensure visibility */
    max-width: calc(100% - 20px); /* Prevent horizontal overflow */
    background-color: #f5f5f7; /* Light Gray */
    border: 1px solid #133F94; /* Deep Blue */
    border-radius: 8px;
    padding: 10px;
    z-index: 999; /* Ensure the dropdown is above other elements */
    box-sizing: border-box; /* Include padding and border in element's width and height */
  }

  .nav-links.active li {
    margin: 10px 0;
    text-align: center; /* Center text for better alignment */
  }

  .header-buttons {
    display: none;
  }
}

@media (max-width: 480px) {
  .logo img {
    height: 60px;
  }

  .logo span {
    font-size: 24px;
  }

  .header-buttons .button-outline,
  .header-buttons .button-filled {
    font-size: 14px;
    padding: 6px 12px;
  }
}
