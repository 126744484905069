/* Overall How It Works Section Styling */
.how-it-works-section {
    background-color: rgb(250, 250, 252); /* Very Light Gray */
    color: rgb(29, 29, 31); /* Dark Gray */
    padding: 80px 20px; /* Generous padding for spacing */
    text-align: center; /* Centered text for focus */
    overflow: hidden; /* Hide overflow to maintain clean edges */
    position: relative; /* Relative positioning for contained elements */
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; /* Modern sans-serif font */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions for background and text color */
}

/* Hover Effect for Section */
.how-it-works-section:hover {
    background-color: rgb(240, 240, 242); /* Slightly darker on hover */
    color: rgb(20, 20, 20); /* Darker text color on hover for contrast */
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
    .how-it-works-section {
        padding: 60px 15px; /* Adjusted padding for smaller screens */
    }
}


/* Section Title Styling */
.section-title {
    font-size: 2.5rem; /* Large font size for emphasis */
    font-weight: bold; /* Bold font weight for importance */
    margin-bottom: 60px; /* Generous bottom margin for spacing */
    color: rgb(29, 29, 31); /* Apple Black */
    opacity: 0; /* Initial opacity for fade-in effect */
    animation: fadeIn 1s ease-in-out forwards; /* Fade-in animation */
    animation-delay: 0.5s; /* Delay before animation starts */
    letter-spacing: 1.2px; /* Slight letter spacing for readability */
    text-transform: uppercase; /* Uppercase text for uniformity */
    position: relative; /* Positioning for additional effects */
    overflow: hidden; /* Ensuring no overflow */
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition effects */
}

/* Hover Effect for Section Title */
.section-title:hover {
    color: rgb(0, 122, 255); /* Apple Blue on hover */
    transform: scale(1.05); /* Slight scale up on hover */
}

/* Fade-in Animation */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px); /* Start slightly below */
    }
    100% {
        opacity: 1;
        transform: translateY(0); /* End at the original position */
    }
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
    .section-title {
        font-size: 2rem; /* Adjust font size for smaller screens */
        margin-bottom: 40px; /* Adjust margin for smaller screens */
    }
}

/* Step Box Styling */
.how-it-works-step {
    text-align: left; /* Align text to the left for readability */
    margin-top: 60px;
    background: rgb(245, 245, 247); /* Light Gray */
    padding: 30px; /* Generous padding for content spacing */
    border-radius: 12px; /* Softer look with rounded corners */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    margin-bottom: 40px; /* Space below each step */
    transform: translateY(20px); /* Initial downward position for animation */
    opacity: 0; /* Initial opacity for fade-in effect */
    transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease, opacity 0.3s ease; /* Smooth transitions */
    animation: fadeInUp 1s ease-in-out forwards; /* Fade-in and upward motion animation */
    animation-delay: 1s; /* Delay before animation starts */
    position: relative; /* Positioning for additional elements */
    overflow: hidden; /* Ensure no overflow */
    cursor: pointer; /* Pointer cursor to indicate interactiveness */
}

/* Hover Effect for Step Box */
.how-it-works-step:hover {
    transform: translateY(0); /* Reset position to original */
    background: rgb(255, 255, 255); /* White background on hover */
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover for emphasis */
    transition: transform 0.3s ease, background 0.3s ease, box-shadow 0.3s ease; /* Smooth transition effects */
}

/* Step Box Inner Elements */
.how-it-works-step::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(to right, #133F94, #0a285e); /* Gradient bar at the top */
    transition: width 0.3s ease;
}

.how-it-works-step:hover::before {
    width: 0; /* Hide gradient bar on hover */
    transition: width 0.3s ease;
}

/* Animation for Step Box */
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px); /* Start slightly below */
    }
    100% {
        opacity: 1;
        transform: translateY(0); /* End at original position */
    }
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
    .how-it-works-step {
        padding: 20px; /* Adjust padding for smaller screens */
        margin-bottom: 30px; /* Adjust margin for smaller screens */
    }
}


/* Step Title Styling */
.step-title {
    font-size: 1.75rem; /* Large font size for emphasis */
    font-weight: bold; /* Bold font weight for importance */
    margin-bottom: 20px; /* Space below the title */
    color: #133F94; /* Blue for differentiation */
    opacity: 0; /* Initial opacity for fade-in effect */
    animation: fadeIn 1s ease-in-out forwards; /* Fade-in animation */
    animation-delay: 1.5s; /* Delay before animation starts */
    letter-spacing: 1px; /* Slight letter spacing for readability */
    text-transform: capitalize; /* Capitalize each word for a polished look */
    position: relative; /* Positioning for additional effects */
    overflow: hidden; /* Ensuring no overflow */
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition effects */
    z-index: 1; /* Ensure title is above other elements */
}

/* Hover Effect for Step Title */
.step-title:hover {
    color: #0a285e; /* Darker blue on hover for contrast */
    transform: scale(1.05); /* Slight scale up on hover */
}

/* Step Title Underline Animation */
.step-title::after {
    content: "";
    display: block;
    width: 0;
    height: 3px;
    background: #133F94; /* Blue underline */
    transition: width 0.3s ease;
    margin-top: 5px;
    position: absolute;
    bottom: -5px;
    left: 0;
}

.step-title:hover::after {
    width: 100%; /* Full width underline on hover */
}

/* Step Description Styling */
.step-description {
    font-size: 1rem; /* Standard font size */
    line-height: 1.8; /* Increased line-height for readability */
    color: rgb(79, 79, 79); /* Medium Gray */
    margin-bottom: 20px; /* Space below the description */
    opacity: 0; /* Initial opacity for fade-in effect */
    animation: fadeIn 1s ease-in-out forwards; /* Fade-in animation */
    animation-delay: 2s; /* Delay before animation starts */
    text-align: justify; /* Justified text for clean alignment */
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
    position: relative; /* Positioning for additional effects */
}

/* Hover Effect for Step Description */
.how-it-works-step:hover .step-description {
    color: rgb(59, 59, 59); /* Darker Gray on hover */
    transform: translateX(10px); /* Slight shift on hover */
}

/* Animation for Step Title and Description */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px); /* Start slightly below */
    }
    100% {
        opacity: 1;
        transform: translateY(0); /* End at original position */
    }
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
    .step-title {
        font-size: 1.5rem; /* Adjust font size for smaller screens */
        margin-bottom: 15px; /* Adjust margin for smaller screens */
    }

    .step-description {
        font-size: 0.875rem; /* Adjust font size for smaller screens */
        margin-bottom: 15px; /* Adjust margin for smaller screens */
    }
}


/* Image Styling */
.how-it-works-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transform: scale(0.95);
    opacity: 0;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: fadeInZoom 1s ease-in-out forwards;
    animation-delay: 2.5s;
}

.how-it-works-image:hover {
    transform: scale(1.05);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */
@media (max-width: 768px) {
    .section-title {
        font-size: 2rem;
    }

    .step-title {
        font-size: 1.5rem;
    }

    .step-description {
        font-size: 0.875rem;
    }

    .how-it-works-image {
        margin-bottom: 15px;
    }
}

/* Animations */
@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInZoom {
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Button Styling (if applicable) */
.cta-button {
    display: inline-block;
    padding: 12px 24px;
    background-color: #133F94; /* Blue */
    color: white;
    text-transform: uppercase;
    border-radius: 8px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-top: 20px;
    font-weight: bold;
    letter-spacing: 1px;
    text-decoration: none;
}

.cta-button:hover {
    background-color: #0a285e; /* Darker Blue */
    transform: scale(1.05);
}

/* Divider Styling (if applicable) */
.divider {
    width: 80px;
    height: 4px;
    background-color: #133F94; /* Blue */
    margin: 20px auto;
    border-radius: 2px;
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
    animation-delay: 1.2s;
}
