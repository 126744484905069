/* Overall Footer Styling */
.footer {
    background-color: rgb(228, 229, 231); /* Background color provided */
    color: #333333; /* Dark Gray for high contrast */
    padding: 60px 20px;
    text-align: left;
    width: 100%;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1000;
    transition: all 0.3s ease;
    overflow-x: hidden; /* Prevent horizontal scroll */
    box-sizing: border-box; /* Include padding in width */
    border-radius: 20px; /* Rounded corners on all sides */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* Apple preferred font stack */
}

/* Footer Title Styling */
.footer-title {
    font-size: 2rem; /* Increased font size for emphasis */
    font-weight: 700; /* Stronger font weight for better readability */
    margin-bottom: 25px; /* Slightly larger margin for better spacing */
    color: #1d1d1f; /* Dark Gray for titles */
    letter-spacing: 0.05em; /* Slightly wider letter spacing for a modern look */
    text-transform: uppercase; /* Uppercase for a bold, clean appearance */
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for hover effects */
}

.footer-title:hover {
    color: #0071e3; /* Apple Blue for hover */
    transform: scale(1.05); /* Slight scale-up effect on hover */
}

/* Footer Description Styling */
.footer-description {
    font-size: 1.1rem; /* Slightly larger font size for readability */
    margin-bottom: 25px; /* Consistent spacing with title */
    line-height: 1.8; /* Increased line height for better readability */
    color: #4d4d4f; /* Medium Gray for descriptions */
    transition: color 0.3s ease; /* Smooth transition for hover effects */
}

.footer-description:hover {
    color: #1d1d1f; /* Darker gray on hover for contrast */
}

/* Footer Links Styling */
.footer-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Flexbox gap for consistent spacing */
}

.footer-links li {
    margin-bottom: 0; /* Remove individual margin, using gap instead */
}

.footer-links a {
    color: #1d1d1f; /* Dark Gray for links */
    text-decoration: none;
    font-size: 1.1rem; /* Slightly larger font size for better readability */
    transition: color 0.3s ease, text-decoration 0.3s ease; /* Smooth transitions for hover effects */
    position: relative; /* For underline effect */
}

.footer-links a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #0071e3; /* Apple Blue for underline */
    transition: width 0.3s ease; /* Smooth transition for underline effect */
    position: absolute;
    bottom: -3px;
    left: 0;
}

.footer-links a:hover {
    color: #0071e3; /* Apple Blue for link hover */
}

.footer-links a:hover::after {
    width: 100%; /* Full width underline on hover */
}


.footer-bottom {
    margin-top: 40px;
    border-top: 1px solid rgba(29, 29, 31, 0.2); /* Subtle divider */
    padding-top: 20px;
    text-align: center;
}

.footer-copy {
    font-size: 0.875rem;
    color: #4d4d4f; /* Medium Gray for copy */
}

@media (max-width: 768px) {
    .footer {
        text-align: center;
        padding: 40px 20px;
    }

    .footer-title {
        font-size: 1.5rem;
    }

    .footer-description {
        font-size: 0.875rem;
    }

    .footer-links {
        text-align: center;
        padding: 0; /* Ensure links don't overflow on smaller screens */
    }

    .footer-links li {
        display: inline-block;
        margin: 0 10px;
    }

    .footer-bottom {
        margin-top: 20px;
    }
}
