/* Modal Styling */
.dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.75rem;
    font-weight: bold;
    color: #133F94; /* Blue */
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  
  .dialog-close {
    color: #133F94; /* Blue */
  }
  
  .dialog-content {
    font-size: 1rem;
    color: #4F4F4F; /* Medium Gray */
    line-height: 1.8;
    padding: 20px 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  
  /* Modal Container Styling */
  .dialog-container {
    border-radius: 12px;
    overflow: hidden; /* Ensure the border-radius is applied to the content */
  }
  
  /* Modal Fade-In Animation */
  @keyframes fadeInModal {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .dialog-content {
    opacity: 0;
    animation: fadeInModal 0.5s ease-in-out forwards;
  }
  