/* Overall Hero Section Styling */
.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  background-color: rgb(245, 245, 247); /* Background color */
  color: rgb(29, 29, 31); /* Dark Gray */
  text-align: left;
  padding: 0 40px;
  overflow: hidden;
  position: relative;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  opacity: 0;
  transition: background-color 0.3s ease;
}

/* Animation for Hero Section Background */
@keyframes heroBgFadeIn {
  from {
    background-color: rgb(255, 255, 255); /* White */
  }
  to {
    background-color: rgb(245, 245, 247); /* Light Gray */
  }
}

@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0 20px;
    height: auto;
    padding-top: 60px; /* Add padding to bring down the text */
  }
}

.hero-section.animate {
  opacity: 1;
  animation: heroBgFadeIn 2s ease-in-out;
}

/* Hero Content Styling */
.hero-section .hero-content {
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 50%;
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
}

.hero-section.animate .hero-content {
  opacity: 1;
  transform: translateX(0);
}

@media (max-width: 768px) {
  .hero-section .hero-content {
    align-items: center;
    max-width: 100%;
    margin-bottom: 20px;
  }
}

/* Hero Title Styling */
.hero-section .hero-title {
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: rgb(29, 29, 31); /* Apple Black */
  position: relative;
  line-height: 1.2;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 2s ease-in-out, transform 2s ease-in-out;
}

.hero-section.animate .hero-title {
  opacity: 1;
  transform: translateY(0);
}

.hero-section .hero-title::after {
  content: '';
  display: block;
  width: 100px;
  height: 5px;
  background-color: #133F94; /* Blue */
  margin: 20px auto 0; /* Center the line */
  opacity: 0;
  transition: width 1s ease-in-out, opacity 1s ease-in-out;
}

.hero-section.animate .hero-title::after {
  opacity: 1;
  width: 100px;
}

@media (max-width: 768px) {
  .hero-section .hero-title {
    font-size: 2rem;
  }
}

/* Hero Subtitle Styling */
.hero-section .hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 30px;
  color: rgb(79, 79, 79); /* Medium Gray */
  line-height: 1.5;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
}

.hero-section.animate .hero-subtitle {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 768px) {
  .hero-section .hero-subtitle {
    font-size: 1.2rem;
  }
}

/* Hero Button Styling */
.hero-section .hero-button {
  background-color: #133F94; /* Blue */
  color: rgb(245, 245, 247); /* Light Gray */
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 3s ease-in-out, transform 3s ease-in-out;
}

.hero-section.animate .hero-button {
  opacity: 1;
  transform: translateY(0);
}

.hero-section .hero-button:hover {
  background-color: #0f2e6f;
  color: rgb(234, 234, 234); /* Light Gray */
}

/* Hero Image Styling */
.hero-section .hero-image {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease, filter 0.3s ease;
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
}

.hero-section.animate .hero-image {
  opacity: 1;
  transform: translateX(0);
}

.hero-section .hero-image:hover {
  transform: scale(1.05);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.7);
  filter: brightness(1.1);
}

@media (max-width: 768px) {
  .hero-section .hero-image {
    max-width: 80%;
    margin: 20px 0;
  }
}

/* Animation and Interaction Enhancements */
.hero-section .hero-image:focus, .hero-section .hero-image:active {
  outline: none;
  transform: scale(1.1);
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.9);
  filter: brightness(1.2);
}

.hero-section .hero-image:focus-visible {
  outline: 2px solid rgb(234, 234, 234); /* Light Gray */
}
