/* Container Styling */
.about-us-container {
  padding: 80px 20px;
  background: url('../../public/assets/staff.png') no-repeat center center;
  background-size: cover; /* Ensure background covers the entire section */
  text-align: center;
  overflow: hidden;
  position: relative;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  min-height: 100vh; /* Ensure the container covers the full viewport height */
  width: 100vw; /* Ensure the container covers the full viewport width */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeInBackground 1.5s ease-in-out;
  margin: 0; /* Remove any default margin */
  margin-top: 60px;
  margin-bottom: 60px;
  border-radius: 60px;
  z-index: 0;
}

/* Background Overlay */
.about-us-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(245, 245, 247, 0.2); /* Light Gray Overlay */
  z-index: 1;
}

/* Background Fade-In Animation */
@keyframes fadeInBackground {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Title Styling */
.about-us-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 60px;
  color: #133F94; /* Blue */
  opacity: 0;
  animation: fadeInTitle 1s ease-in-out forwards;
  animation-delay: 0.5s;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  z-index: 2;
  position: relative;
}

/* Title Fade-In Animation */
@keyframes fadeInTitle {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Card Grid Styling */
.about-us-grid {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px; /* Add gap between cards */
  padding: 0 20px; /* Add padding to avoid cards touching the edges */
  margin: 0; /* Remove any default margin */
  z-index: 2;
  position: relative;
}

/* Card Styling */
.about-us-card {
  background: rgba(250, 250, 252, 0.8); /* Very Light Gray with slight transparency */
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  flex: 1 1 300px; /* Ensure each card has a minimum width */
  max-width: 300px; /* Limit the maximum width of each card */
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 0;
  animation: fadeInCard 1s ease-in-out forwards;
  animation-delay: 1s;
  margin-top: 60px;
  z-index: 2;
  position: relative;
}

/* Card Hover Effects */
.about-us-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

/* Card Title Styling */
.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #133F94; /* Blue */
  margin-bottom: 10px;
}

/* Card Description Styling */
.card-description {
  font-size: 1rem;
  color: #4f4f4f; /* Medium Gray */
}

/* Card Fade-In Animation */
@keyframes fadeInCard {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Modal Close Button Styling */
.dialog-close {
  position: absolute;
  right: 8px;
  top: 8px;
  color: #4f4f4f; /* Medium Gray */
  z-index: 2;
}

/* Modal Content Styling */
.dialog-content {
  font-size: 1rem;
  line-height: 1.8;
  color: #4f4f4f; /* Medium Gray */
  z-index: 2;
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-us-title {
    font-size: 2.5rem;
  }

  .about-us-grid {
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }

  .about-us-card {
    flex: 1 1 100%;
    max-width: 90%;
  }
}
